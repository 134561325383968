import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialChatState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  adminUsers: [],
  totalPages: 1,
  inboxList: [],
  socket: null,

  msgCount: 0,
  totalMsgPages: 0,
  messageList: [],
  userList: [],

  notifyData: [],
  notifyTotalPages: 1,
  page: 1,
  unreadNotifyCount: 0,
  openRequestsCount: 0,
  choosenUser: {},
  notificationIds: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    inboxFetched: (state, action) => {
      const { totalPages, totalCount, inboxList } = action.payload;
      state.error = null;
      state.inboxList = inboxList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
      state.listLoading = false;
    },
    chatFetched: (state, action) => {
      const { totalMsgPages, msgCount, messageList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.messageList = messageList;
      state.msgCount = msgCount;
      state.totalMsgPages = totalMsgPages;
    },
    updateChat: (state, action) => {
      state.messageList = [...state.messageList, { ...action.payload }];
    },

    createSocket: (state, action) => {
      state.socket = action.payload;
    },

    userListFetched: (state, action) => {
      const { totalPages, totalCount, userList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userList = userList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    notifyDataFetched: (state, action) => {
      const { notifyData, totalPages, page, adminUsers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.notifyData = notifyData?.reverse();
      state.notifyTotalPages = totalPages;
      state.page = page;
      state.adminUsers = adminUsers;
    },
    notifyDataUpdateRecieved: (state, action) => {
      let { createdAt, ...rest } = action.payload;
      createdAt = moment(createdAt).format("MM/DD/YYYY , h:mm:ss,a");
      state.notifyData = [{ createdAt, ...rest }, ...state.notifyData];
      state.unreadNotifyCount = state.unreadNotifyCount + 1;
    },
    unreadNotifyCountFetched: (state, action) => {
      let { count, openRequestsCount, notificationIds } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.unreadNotifyCount = count;
      state.openRequestsCount = openRequestsCount
        ? openRequestsCount
        : state.openRequestsCount;
      state.notificationIds = notificationIds;
    },

    updateOpenRequestCount: (state, action) => {
      let { add, minus } = action.payload;
      if (add) {
        state.openRequestsCount = Number(state.openRequestsCount + 1);
      } else if (minus) {
        state.openRequestsCount = Number(state.openRequestsCount - 1);
      }
    },

    setSelectedUser: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.choosenUser = action.payload;
    },
  },
});
