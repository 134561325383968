import { combineReducers } from "redux";
import * as auth from "../views/pages/login/_redux/authRedux";
import { sideBarSlice } from "src/containers/_redux/sideBarSlice";
import { productSlice } from "src/views/Admin/_redux/productSlice";
import { salesSlice } from "src/views/salesDept/_redux/salesSlice";
import { artSlice } from "src/views/ArtDept/_redux/artSlice";
import { userSlice } from "src/views/Admin/manageUsers/_redux/userSlice";
import { chatSlice } from "src/views/chatManagement/_redux/chatSlice";
import { dashboardSlice } from "src/views/widgets/_redux/dashboardSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  sideBar: sideBarSlice.reducer,
  product: productSlice.reducer,
  sales: salesSlice.reducer,
  art: artSlice.reducer,
  user: userSlice.reducer,
  chat: chatSlice.reducer,
  dashboard:dashboardSlice.reducer
});
