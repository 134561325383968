import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: "responsive",
};

export const sideBarSlice = createSlice({
    name:"sideBar",
    initialState:initialState,
    reducers:{
        changeState:(state,action)=>{
            state.sidebarShow=action.payload.sidebarShow
        }
    }
});
