import { createSlice } from "@reduxjs/toolkit";

const initialProductState = {
  listLoading: false,
  actionsLoading: false,
  couponListLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  page: 1,
  requestedProductList: [],
  requestedProduct: null,

  productForm: {},
  productDetails: {},

  estimateAmount: "",
  packageAmount: "",

  coupon: [],
  couponAdded: {},

  requestHistoryList: [],
  estimateHistoryList: [],
  followUpRequestList: [],

  newRequest: [],
  salesTemplate: [],
  requestedProductById: [],
  product: {},

  updatedData: {},

  newRequestDetailsFetched: {},
  // filteredFollowUpData: {},

  requestedProducts: {},
  updatedRequestedData: undefined,
  updatedShippinPrice: undefined,
  paymentAmount: 0,

  bulkMailData: [],
  // UIToken: "",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const salesSlice = createSlice({
  name: "sales",
  initialState: initialProductState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.couponListLoading = true;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else {
        state.couponListLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    productRequested: (state, action) => {
      const { totalPages, totalCount, requestedProductList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.requestedProductList = requestedProductList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    requestedProduct: (state, action) => {
      const { requestedProduct } = action.payload;
      state.error = null;
      state.requestedProduct = requestedProduct;
      state.listLoading = false;
      state.actionsLoading = false;
    },
    updateRequest: (state, action) => {
      let { requestedProduct } = action.payload;
      delete requestedProduct.estimateSendByUserDetails;
      // delete requestedProduct.assignedSalesUserDetails;

      state.error = null;
      state.requestedProduct = {
        ...state.requestedProduct,
        ...requestedProduct,
      };
      state.listLoading = false;
      state.actionsLoading = false;
    },
    productFormFetched: (state, action) => {
      state.error = null;
      state.productForm = action.payload;
      state.listLoading = false;
    },
    // filteredFollowUpData: (state, action) => {
    //   state.error = null;
    //   state.filteredFollowUpData = action.payload;
    //   state.listLoading = false;
    // },
    productDetailsFetched: (state, action) => {
      state.error = null;
      state.productDetails = action.payload;
      state.listLoading = false;
    },
    amountAdded: (state, action) => {
      state.error = null;
      state.estimateAmount = action.payload;
    },

    mailDataFetched: (state, action) => {
      const bulkMailData = action.payload;
      state.error = null;
      state.bulkMailData = bulkMailData;
      state.listLoading = false;
    },

    newRequestDetailsFetched: (state, action) => {
      const newRequestDetailsFetched = action.payload;
      state.error = null;
      state.newRequestDetailsFetched = newRequestDetailsFetched;
      state.listLoading = false;
    },

    packageAmountAdded: (state, action) => {
      state.error = null;
      state.packageAmount = action.payload;
    },

    couponDetails: (state, action) => {
      state.error = null;
      state.couponAdded = action.payload;
    },

    couponFetched: (state, action) => {
      const { totalPages, totalCount, coupon } = action.payload;
      state.couponListLoading = false;
      state.error = null;
      state.coupon = coupon;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    requestedHistory: (state, action) => {
      const { totalPages, totalCount, requestHistoryList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.requestHistoryList = requestHistoryList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    estimateHistory: (state, action) => {
      const { page, totalPages, totalCount, estimateHistoryList } =
        action.payload;
      state.listLoading = false;
      state.error = null;
      state.estimateHistoryList = estimateHistoryList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
      state.page = page;
    },
    followUpRequests: (state, action) => {
      const { totalPages, totalCount, followUpRequestList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.followUpRequestList = followUpRequestList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    createRequestFetched: (state, action) => {
      const { newRequest } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.newRequest = newRequest;
    },
    salesTemplateFetched: (state, action) => {
      const { salesTemplate } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.salesTemplate = salesTemplate;
    },
    requestedProductById: (state, action) => {
      const { requestedProductById, requestedProductDetails } = action.payload;
      state.error = null;
      state.requestedProductById = requestedProductById;
      state.requestedProductDetails = requestedProductDetails;
      state.listLoading = false;
      state.actionsLoading = false;
    },
    requestedProductInAdditionalEstimate: (state, action) => {
      const { requestedProductById, requestedProductDetails } = action.payload;
      state.error = null;
      state.requestedProductById = requestedProductById;
      state.requestedProductDetails = requestedProductDetails;
      state.listLoading = false;
      state.actionsLoading = false;
    },
    updateData: (state, action) => {
      const { updatedData } = action.payload;
      state.updatedData = updatedData;
      state.error = null;
      state.listLoading = false;
      state.actionsLoading = false;
    },

    // ======================convert to live Job===================

    requestItemFetched: (state, action) => {
      state.requestedProducts = action.payload;
      state.listLoading = false;
      state.error = null;
    },

    updatedRequestedData: (state, action) => {
      let { updatedRequestedData, updatedShippinPrice, paymentAmount } =
        action.payload;
      state.updatedRequestedData = updatedRequestedData;
      state.updatedShippinPrice = updatedShippinPrice;
      state.paymentAmount = paymentAmount;
    },

    // paymentUITokenFetched: (state, action) => {
    //   state.UIToken = action.payload.token;
    //   state.actionsLoading = false;
    //   state.error = null;
    // },
  },
});
