import axios from "axios";

const GET_INBOX_LIST = "v1/chat/chatManagement/listInbox";
const GET_MESSAGE_HISTORY = "v1/chat/chatManagement/listMessages";
const SEND_MESSAGE = "v1/chat/chatManagement/sendChat";
const GET_USER_LIST = "v1/chat/chatManagement/listAllUsers";
//notification
const GET_NOTIFY_LIST = "v1/notification/getNotification";
const GET_UNREAD_NOTIFY_COUNT = "v1/notification/getUnreadNotificationCount";
const REMOVE_INBOX = "v1/chat/chatManagement/removeFromInbox";
const UPDATE_NOTIFY_READ_COUNT = "v1/notification/updateNotification";

// get inbox list
export function getInboxList(params) {
  return axios.post(GET_INBOX_LIST, params);
}

// get chat history
export function getChatHistory(params) {
  return axios.post(GET_MESSAGE_HISTORY, params);
}

// send message
export function sendMessage(params) {
  return axios.post(SEND_MESSAGE, params);
}

// get all users
export function getUserList(params) {
  return axios.post(GET_USER_LIST, params);
}

//notification
export function getNotifyList(params) {
  return axios.post(GET_NOTIFY_LIST, params);
}

export function getUnreadNotifyCount(params) {
  return axios.post(GET_UNREAD_NOTIFY_COUNT, params);
}

// remove user from inbox list
export function removeFromInbox(params) {
  return axios.post(REMOVE_INBOX, params);
}

export function updateNotifyReadStatus(params) {
  return axios.post(UPDATE_NOTIFY_READ_COUNT, params);
}
