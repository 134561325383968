import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  userList: [],
  userDetails:{},
  error: null,
  totalCount: 0,
  totalPages: 1,
  deletedUserList: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      callSuccess: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
  
      userListFetched: (state, action) => {
        const { totalPages, totalCount, userList } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.userList = userList;
        state.totalCount = totalCount;
        state.totalPages = totalPages;
      },
      userDetailsFetched: (state, action) => {
        state.userDetails = action.payload;
        state.listLoading = false;
        state.error = null;
      },

      deletedUsersListFetched: (state, action) => {
        const { totalPages, totalCount, deletedUserList } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.deletedUserList = deletedUserList;
        state.totalCount = totalCount;
        state.totalPages = totalPages;
      },
    }
})  