// import Swal from "sweetalert2";
import { actions } from "../views/pages/login/_redux/authRedux";
import { renewAccessToken } from "../views/pages/login/_redux/authCrud";

// const toast = Swal.mixin({
//   toast: true,
//   position: "top-end",
//   showConfirmButton: false,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener("mouseenter", Swal.stopTimer);
//     toast.addEventListener("mouseleave", Swal.resumeTimer);
//   },
// });

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      if (!`${config.url}`.includes("http")) {
        const {
          auth: { accessToken },
        } = store.getState();
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        config.url = process.env.REACT_APP_BACKEND_API + config.url;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function(error) {
      const originalRequest = error.config;
      if (
        error?.response?.data?.type ===
          process.env.REACT_APP_TOKEN_EXPIRY_ERROR_TYPE &&
        !originalRequest._retry
      ) {
        const {
          auth: { user },
        } = store.getState();
        originalRequest._retry = true;
        let tokenGetObj = {
          loggedInUserId: user.userId,
          trackingPriority: 0,
          frontendAction: " ",
          refreshToken: user.refreshToken,
        };
        const {
          data: {
            data: { accessToken },
          },
        } = await renewAccessToken(tokenGetObj);
        if (accessToken) {
          store.dispatch(actions.renewAccessToken({ accessToken }));
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return Promise.resolve(axios(originalRequest));
        } else {
        //   toast.fire({
        //     title: "Login Corrupted.",
        //     icon: "error",
        //   });
        console.log("Login currupted")
          store.dispatch(actions.logout());
        }
      } else if (
        error?.response?.data?.type ===
          process.env.REACT_APP_REFRESH_TOKEN_ERROR_TYPE &&
        !originalRequest._retry
      ) {
        // toast.fire({
        //   title: "Login Expired.",
        //   icon: "error",
        // });
        console.log("Login expired")
        store.dispatch(actions.logout());
      } else if (
        (error?.response?.data?.type === process.env.REACT_APP_ROLE_NO_RIGHT ||
          error?.response?.data?.type ===
            process.env.REACT_APP_NO_PERMISSION) &&
        !originalRequest._retry
      ) {
        console.log("Permission Error");
        // CustomToast.fire({ icon: "error", title: "Authenticated user role has no rights to perform the action." });
      }
      return Promise.reject(error);
    }
  );
}
