import axios from "axios";

// Renew Token
export const RENEW_ACCESS_TOKEN =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/auth/renewAccessToken";
export const SEND_LOGIN_OTP =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/user/auth/sendOtp";
export const VERIFY_LOGIN_OTP =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/user/auth/verifyOtp";
export const USER_LOGIN =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/user/auth/loginUser";
export const FORGOT_PASSWORD =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/user/auth/forgotPassword";
export const RESET_PASSWORD =
  process.env.REACT_APP_BACKEND_PUBLIC_API + "v1/user/auth/resetPassword";

// GET =>  renew AccessToken
export function renewAccessToken(refreshToken) {
  return axios.post(RENEW_ACCESS_TOKEN, refreshToken);
}

//  login using username and password
export function userLogin(loginParams) {
  return axios.post(USER_LOGIN, {
    ...loginParams,
    trackingPriority: 0,
    frontendAction: "user.auth.loginUser",
  });
}

//  forgot password
export function forgotPassword(loginParams) {
  return axios.post(FORGOT_PASSWORD, {
    ...loginParams,
    trackingPriority: 0,
    frontendAction: "user.auth.forgotPassword",
  });
}

//  reset password
export function resetPassword(loginParams) {
  return axios.post(RESET_PASSWORD, {
    ...loginParams,
    trackingPriority: 0,
    frontendAction: "user.auth.resetPassword",
  });
}

//  get Otp
export function sendLoginOtp(email) {
  return axios.post(SEND_LOGIN_OTP, {
    email,
    role: "admin",
    trackingPriority: 0,
    frontendAction: "User.login",
  });
}

//verify login otp
export function verifyLoginOtp(otp, email) {
  return axios.post(VERIFY_LOGIN_OTP, {
    otp,
    email,
    trackingPriority: 0,
    frontendAction: "User.login",
  });
}
