import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  shopIdSet: "setShopID",
  RenewAccessToken: "[Renew Access Token] Action",
};

const initialAuthState = {
  user: undefined,
  // user: {userId:"UserAdmin001"},
  accessToken: undefined,
  email: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "mmPrint-auth",
    whitelist: ["user", "accessToken", "email"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { accessToken } = action.payload.data;
        return { ...state, accessToken, user: action.payload.data };
      }

      case actionTypes.Register: {
        const {email} = action.payload.data;
        return {
          accessToken: undefined,
          email:email,
          user: undefined,
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.clear();
        return initialAuthState;
      }
      case actionTypes.shopIdSet: {
        let temp = state;
        temp.user.shopId = action.payload;
        return temp;
      }
      case actionTypes.RenewAccessToken: {
        const { accessToken } = action.payload;
        return { ...state, accessToken };
      }
      default:
        return state;
    }
  }
);

export const actions = {
    login: (loginData) => ({
      type: actionTypes.Login,
      payload: { ...loginData }
    }),
  
    register: (registerData) => ({
      type: actionTypes.Register,
      payload: { ...registerData },
    }),
  
    logout: () => ({ type: actionTypes.Logout }),
    setShopId: (shopId) => ({
      type: actionTypes.shopIdSet,
      payload: shopId
    }),
  
    renewAccessToken: (access_token_obj) => (
      {
        type: actionTypes.RenewAccessToken,
        payload: access_token_obj,
      }),
  };
