export const FeedBackTextGenerator = (touched, error) => {
  return error || touched ? (
    <div
      className="invalid-feedback"
      style={{ display: "block" }}
    >
      {error}
    </div>
  ) : null
};

export const NewReqFeedBackTextGenerator = (touched, error, submitVal) => {
  return (error && touched) || submitVal ? (
    <div
      className="invalid-feedback"
      style={{ display: "block" }}
    >
      {error}
    </div>
  ) : null
};