import * as requestFromServer from "./chatCrud";
import { chatSlice, callTypes } from "./chatSlice";
import { Toaster } from "src/views/components/Toaster";
import moment from "moment";
import { useSelector, shallowEqual } from "react-redux";
var _ = require("lodash");

const { actions } = chatSlice;

const getLabel = (data) => {
  switch (data) {
    case true:
      return "Active";
    case false:
      return "InActive";
    default:
      return null;
  }
};

export const getInboxList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getInboxList(queryParams)
    .then((response) => {
      const { totalPages, total: totalCount, rows: inboxList } = response.data;

      dispatch(actions.inboxFetched({ totalPages, totalCount, inboxList }));
    })
    .catch((error) => {
      error.clientMessage = "can't Fetch inbox";
      dispatch(
        actions.inboxFetched({ totalCount: 0, totalPages: 1, inboxList: null })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearChatBox = (queryParams) => (dispatch) => {
  dispatch(
    actions.chatFetched({ msgCount: 0, totalMsgPages: 1, messageList: [] })
  );
};

export const updateChatHistory = (queryParams) => (dispatch) => {
  dispatch(actions.updateChat(queryParams));
};

export const createSocket = (queryParams) => (dispatch) => {
  dispatch(actions.createSocket(queryParams));
};

export const getChatHistory = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getChatHistory(queryParams)
    .then((response) => {
      const {
        totalPages: totalMsgPages,
        total: msgCount,
        rows: messageList,
      } = response.data;

      dispatch(
        actions.chatFetched({
          totalMsgPages,
          msgCount,
          messageList: messageList?.reverse(),
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "can't Fetch inbox";
      dispatch(
        actions.chatFetched({
          msgCount: 0,
          totalMsgPages: 1,
          messageList: null,
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const sendMessage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendMessage(queryParams)
    .then((response) => {
      // Toaster({ message: response?.data?.message, variant: "success" });
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    })
    .catch((error) => {
      Toaster({ message: error?.response?.data?.message, variant: "error" });
      dispatch(actions.catchError({ callType: callTypes.action }));
    });
};

export const getUserList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserList(queryParams)
    .then((response) => {
      if (response?.data?.success) {
        const { totalPages, total: totalCount, rows } = response.data.data;

        let userList = [];
        _.map(rows, (val) => {
          let { createdAt, isActive, roleDetails, ...rest } = val;
          let row = { ...rest };
          // row.name = val?.firstName + " " + val?.lastName;
          row.isActive = getLabel(isActive);
          row.roleName = roleDetails?.roleName;
          row.createdAt = moment(createdAt).format("MM/DD/YYYY , h:mm:ss,a");
          userList.push(row);
        });

        dispatch(actions.userListFetched({ totalPages, totalCount, userList }));
      } else {
        dispatch(actions.userListFetched({ totalCount: 0, requestList: null }));
      }
    })
    .catch((error) => {
      error.clientMessage = "can't Fetch requests";
      dispatch(actions.userListFetched({ totalCount: 0, requestList: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateNotifyList = (queryParams) => (dispatch) => {
  dispatch(actions.notifyDataUpdateRecieved(queryParams));
};

export const getNotifyList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotifyList(queryParams)
    .then((response) => {
     const userDetails = JSON.parse(localStorage.getItem('persist:mmPrint-auth'))
    // console.log("useDetails.user======================>>>", userDetails.user)
    let userId = userDetails && userDetails.user ? JSON.parse(userDetails.user).userId : null
    // console.log("userId======================>>>", userId)
      let {
        totalPages,
        page,
        total: totalCount,
        rows,
        adminUsers,
      } = response.data.data;
      adminUsers.splice(0, 0, { value: userId, label: "Me" }, { value: "", label: "All Users" });
      // adminUsers.splice(0, 1, { value: "", label: "All Users" });
      let notifyData = [];
      _.map(rows, (val) => {
        let { createdAt, ...rest } = val;

        rest.createdAt = moment(createdAt).format("MM/DD/YYYY , h:mm:ss,a");
        notifyData.push(rest);
      });

      dispatch(
        actions.notifyDataFetched({
          page,
          totalPages,
          totalCount,
          notifyData: notifyData?.reverse(),
          adminUsers,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "can't Fetch requests";
      dispatch(
        actions.notifyDataFetched({
          totalCount: 0,
          requestList: null,
          adminUsers: [],
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const selectUserAction = (queryParams) => (dispatch) => {
  dispatch(actions.setSelectedUser(queryParams));
};

export const updateOpenRequestCount = (queryParams) => (dispatch) => {
  dispatch(
    actions.updateOpenRequestCount({
      add: queryParams?.add,
      minus: queryParams?.minus,
    })
  );
};

export const getUnreadNotifyCount = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUnreadNotifyCount(queryParams)
    .then((response) => {
      const { count, openRequestsCount, notificationIds } = response.data.data;

      dispatch(
        actions.unreadNotifyCountFetched({
          count,
          openRequestsCount,
          notificationIds,
        })
      );
      return openRequestsCount
    })
    .catch((error) => {
      error.clientMessage = "can't Fetch requests";
      dispatch(
        actions.unreadNotifyCountFetched({
          count: 0,
          openRequestsCount: 0,
          notificationIds: null,
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return error.clientMessage
    });
};

export const clearNotifyCount = () => (dispatch) => {
  dispatch(actions.unreadNotifyCountFetched({ count: 0 }));
};

export const removeUserFromInbox = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeFromInbox(queryParams)
    .then((response) => {
      dispatch(actions.callSuccess({ callType: callTypes.action }));
      Toaster({ message: response?.data?.message, variant: "success" });
    })
    .catch((error) => {
      Toaster({ message: error?.response?.data?.message, variant: "error" });
      dispatch(actions.catchError({ callType: callTypes.action }));
    });
};

export const updateNotifyReadStatus = (queryParams) => (dispatch) => {
  return requestFromServer
    .updateNotifyReadStatus(queryParams)
    .then((response) => {
      dispatch(actions.callSuccess({ callType: callTypes.action }));
    });
};
