import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import './style.css'
import 'font-awesome/css/font-awesome.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from "axios"
import * as serviceWorker from './serviceWorker';
import * as _redux from "./redux"
import { persistor } from "./redux/store";

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './redux/store'

React.icons = icons
_redux.setupAxios(axios, store);

ReactDOM.render(
  <Provider store={store} persistor={persistor}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
