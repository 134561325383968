import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  requestList: [],
  requestItem: {},
  requestHistory: [],
  error: null,
  totalCount: 0,
  totalPages: 1,
  onlinOrderList: [],
  originalRequestList: [],
  artTemplate: [],
  historyTotalPages: 1,
  page: 1,
  proofFile: {},
  userDetails: {},
  userAddresses: {},
  companyNames: {}
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const artSlice = createSlice({
  name: "art",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    requestListFetched: (state, action) => {
      const { totalPages, totalCount, requestList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.requestList = requestList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    requestItemFetched: (state, action) => {
      state.requestItem = action.payload;
      state.listLoading = false;
      state.error = null;
    },
    proofItemFetched: (state, action) => {
      state.proofFile = action.payload;
      state.listLoading = false;
      state.error = null;
    },
    requestHistoryFetched: (state, action) => {
      const { requestHistory, totalPages, page } = action.payload;
      state.requestHistory = requestHistory;
      state.listLoading = false;
      state.historyTotalPages = totalPages;
      state.page = page;
      state.error = null;
    },
    onlineOrders: (state, action) => {
      const { totalPages, totalCount, onlinOrderList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.onlinOrderList = onlinOrderList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },
    originalReqFetched: (state, action) => {
      const { originalRequestList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.originalRequestList = originalRequestList;
    },
    artTemplateFetched: (state, action) => {
      const { artTemplate } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.artTemplate = artTemplate;
    },
    userDetailsFetched: (state, action) => {
      state.error = null;
      state.userDetails = action.payload;
      state.listLoading = false;
    },
    companyNamesFetched: (state, action) => {
      state.error = null;
      state.companyNames = action.payload;
      state.listLoading = false;
    },
    userAddressesFetched: (state, action) => {
      state.error = null;
      state.userAddresses = action.payload;
      state.listLoading = false;
    },
  },
});
