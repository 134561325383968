import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/forgot-password/ResetPassword";
let path = "";
// Containers
const TheLayout = React.lazy(() => import("../containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("../views/pages/login/Login"));
const Register = React.lazy(() => import("../views/pages/register/Register"));
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("../views/pages/page500/Page500"));
const VerifyOtp = React.lazy(() => import("../views/pages/login/VerifyOtp"));

export default function MainRoute() {
  let history = useHistory();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  if (window.location.href !== process.env.REACT_APP_ADMIN_FRONT_END_URL+"login") {
    path = window.location.href;
  }
  return (
    <Switch>
      <Route
        exact
        path="/login"
        name="Login Page"
        render={(props) =>
          isAuthorized ? (window.location.href = path) : <Login {...props} />
        }
      />
      <Route
        exact
        path="/forgot-password"
        name="Forgot Password Page"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        exact
        path="/reset-password"
        name="Reset Password Page"
        render={(props) => <ResetPassword {...props} />}
      />
      <Route
        exact
        path="/auth/user/verify"
        name="Verify Otp Page"
        render={(props) =>
          isAuthorized ? history.push("/") : <VerifyOtp {...props} />
        }
      />
      <Route
        exact
        path="/register"
        name="Register Page"
        render={(props) => <Register {...props} />}
      />
      <Route
        exact
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />
      <Route
        exact
        path="/500"
        name="Page 500"
        render={(props) => <Page500 {...props} />}
      />
      <Route
        path="/"
        name="Home"
        render={(props) =>
          isAuthorized ? <TheLayout {...props} /> : history.push("/login")
        }
      />
    </Switch>
  );
}
