import { createSlice } from "@reduxjs/toolkit";

const initialProductState = {
  listLoading: false,
  actionsLoading: false,
  productList: [],
  attributeList: [],
  attributeOptionList: [],
  attributeTypeList: [],
  productTypeList: [],
  productToEdit: {},
  mappedData: {},
  mappedRows: ["1"],

  coupones: [],
  couponToEdit: {},

  error: null,
  totalCount: 0,
  totalPages: 1,
  templateList: [],
  templateDetails: [],
  assignTemplate: [],
  contactsList: [],
  createdExcel: [],
  deletedUsers: [],

  prodAttributes: [],
  productTypesList: [],
  attributeDetails: [],

  contactDetails: [],
  contactTotalCount: 0,
  contactTotalPages: 1,
  contactActivePage: 1,
  userDetails: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    productListFetched: (state, action) => {
      const { totalPages, totalCount, productList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.productList = productList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    couponesFetched: (state, action) => {
      const { totalPages, totalCount, coupones } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.coupones = coupones;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    couponesToEditFetched: (state, action) => {
      const { couponToEdit } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.couponToEdit = couponToEdit;
    },

    productFetched: (state, action) => {
      const { productToEdit } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.productToEdit = productToEdit;
    },

    mappedDataFetched: (state, action) => {
      state.listLoading = false;
      const { mappedData, mappedOptions } = action.payload;
      state.error = null;
      state.mappedData = mappedData;
      state.mappedRows = mappedOptions;
    },

    attributeListFetched: (state, action) => {
      const { attributeList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.attributeList = [...state.attributeList, ...attributeList];
    },

    clearAttributeList: (state) => {
      state.attributeList = [];
    },

    attributeOptionListFetched: (state, action) => {
      const { attributeTypeList, attributeOptionList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.attributeTypeList = attributeTypeList;
      state.attributeOptionList = attributeOptionList;
    },

    productTypeListFetched: (state, action) => {
      const { productTypeList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.productTypeList = productTypeList;
    },

    templateListFetched: (state, action) => {
      const { totalPages, totalCount, templateList } = action.payload;
      state.error = null;
      state.templateList = templateList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    templateDetailsFetched: (state, action) => {
      const { totalPages, totalCount, templateDetails } = action.payload;
      state.error = null;
      state.templateDetails = templateDetails;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    assignTemplateFetched: (state, action) => {
      const { assignTemplate } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.assignTemplate = assignTemplate;
    },

    templateListLoadingToggler: (state, action) => {
      if (typeof action.payload == "boolean") {
        state.listLoading = action.payload;
      } else {
        state.listLoading = !state.listLoading;
      }
    },

    templateDetailsLoadingToggler: (state, action) => {
      if (typeof action.payload == "boolean") {
        state.listLoading = action.payload;
        state.actionsLoading = action.payload;
      } else {
        state.listLoading = !state.listLoading;
        state.actionsLoading = !state.actionsLoading;
      }
    },

    contactsListFetched: (state, action) => {
      const { totalPages, totalCount, contactsList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.contactsList = contactsList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    createExcelFetched: (state, action) => {
      const { createdExcel } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.createdExcel = createdExcel;
    },

    deletedUsersListFetched: (state, action) => {
      const { totalPages, totalCount, deletedUsers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.deletedUsers = deletedUsers;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    productAttributesFetched: (state, action) => {
      const { totalPages, totalCount, prodAttributes } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.prodAttributes = prodAttributes;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    productTypesFetched: (state, action) => {
      const { totalPages, totalCount, productTypesList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.productTypesList = productTypesList;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    attributeDetailsFetched: (state, action) => {
      const { attributeDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.attributeDetails = attributeDetails;
    },

    contactDeatailsFetched: (state, action) => {
      const { page, totalPages, totalCount, contactDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.contactDetails = contactDetails;
      state.contactTotalCount = totalCount;
      state.contactTotalPages = totalPages;
      state.contactActivePage = page;
    },

    getUserDetails: (state, action) => {
      const { userDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userDetails = userDetails;
    },
  },
});
