import { createSlice } from "@reduxjs/toolkit";

const initialDashoardState = {
  overview: {
    graphData: [],

    artUsersCount: 0,
    salesUsersCount: 0,
    consumersCount: 0,

    productsCount: 0,
    completedRequestCount: 0,
    totalRequestCount: 0,

    requestsInSalesCount: 0,
    requestsInArtCount: 0,
  },
};

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: initialDashoardState,
  reducers: {
    artUsersCountFetched: (state, action) => {
      const { data } = action.payload;
      state.overview.artUsersCount = data?.count;
    },
    salesUsersCountFetched: (state, action) => {
      const { data } = action.payload;
      state.overview.salesUsersCount = data?.count;
    },
    consumerCountFetched: (state, action) => {
      const { data } = action.payload;
      state.overview.consumersCount = data?.count;
    },
    productsCountFetched: (state, action) => {
      const { data } = action.payload;
      state.overview.productsCount = data?.count;
    },

    requestCountFetched: (state, action) => {
      if (action.payload?.data) {
        let {
          completedRequestCount,
          totalRequestCount,
          requestsInSalesCount,
          requestsInArtCount,
          graphData,
        } = action.payload?.data;

        state.overview.completedRequestCount = completedRequestCount
          ? completedRequestCount
          : 0;
        state.overview.totalRequestCount = totalRequestCount
          ? totalRequestCount
          : 0;
        state.overview.requestsInSalesCount = requestsInSalesCount
          ? requestsInSalesCount
          : 0;
        state.overview.requestsInArtCount = requestsInArtCount
          ? requestsInArtCount
          : 0;
        state.overview.graphData = graphData;
      }
    },
    // completedRequestCountFetched: (state, action) => {
    //   const { data } = action.payload;
    //   state.overview.completedRequestCount = data?.count;
    // },
    // totalRequestCountFetched: (state, action) => {
    //   const { data } = action.payload;
    //   state.overview.totalRequestCount = data?.count;
    // },
    // requestsInSalesCountFetched: (state, action) => {
    //   const { data } = action.payload;
    //   state.overview.requestsInSalesCount = data?.count;
    // },
    // requestsInArtCountFetched: (state, action) => {
    //   const { data } = action.payload;
    //   state.overview.requestsInArtCount = data?.count;
    // },
  },
});
