import React, { useEffect } from "react";

import { HashRouter } from "react-router-dom";

import "./scss/style.scss";
import MainRoute from "./views/MainRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import addNotification from "react-push-notification";
import { io } from "socket.io-client";
import {
  createSocket,
  updateNotifyList,
} from "./views/chatManagement/_redux/chatActions";
import {
  updateChatHistory,
  updateOpenRequestCount,
} from "./views/chatManagement/_redux/chatActions";

var socket = io(process.env.REACT_APP_SOCEKT_URL, {
  transports: ["websocket"],
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const dispatch = useDispatch();

  const onEvent = (message, type) => {
    addNotification({
      title: type,
      subtitle: "New Message is Arrived",
      message: message?.messageValue
        ? message.messageValue
        : message.metaData.notfyHead,
      // message: message?.messageValue?:message?.messageValue:message.metaData.notfyHead,
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
    });
  };

  const { userId, roleId } = useSelector(
    (state) => ({
      userId: state?.auth?.user?.userId,
      roleId: state?.user?.roleId,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(createSocket(socket));
    if (userId) {
      socket.connect();
      socket.emit("create-room", userId);
      socket.emit("joinRoom", userId);

      socket.emit("create-room", "recieveNotification");
      socket.emit("joinRoom", "recieveNotification");
    }
    if (roleId !== "rl_artadmin") {
      socket.emit("create-room", "New_Request_Notification");
      socket.emit("joinRoom", "New_Request_Notification");
    }
    if (roleId !== "rl_salesadmin") {
      socket.emit("create-room", "New_Art_Item_Notification");
      socket.emit("joinRoom", "New_Art_Item_Notification");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // used to listen notification update
  useEffect(() => {
    socket.on("recieveNotification", (notificationRecieved) => {
      console.log(">>>>>>>>>>>notificationRecieved",notificationRecieved)
      //live open request count update
      if (notificationRecieved?.metaData?.addOpenRequestCount) {
        dispatch(updateOpenRequestCount({ add: true, minus: false }));
      } else if (notificationRecieved?.metaData?.minusOpenRequestCount) {
        dispatch(updateOpenRequestCount({ add: false, minus: true }));
      }

      if (notificationRecieved?.metaData?.isThisNotificationIncluded) {
      dispatch(updateNotifyList(notificationRecieved));
      onEvent(notificationRecieved, "New Notification");
      }
    });

    //live push notification
    socket?.on("messageService", (message) => {
      onEvent(message, "New Chat Message");
      dispatch(updateChatHistory(message));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.id]);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer />
        <MainRoute />
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
