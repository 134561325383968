import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import * as yup from "yup";
import { useFormik } from "formik";
import { FeedBackTextGenerator } from "src/views/components/customHtml";
import { resetPassword } from "../login/_redux/authCrud";
import { Toaster } from "src/views/components/Toaster";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
  };
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    otp: yup.string().required("otp is Required"),
    cPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    password: yup.string().required("Password is Required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formData, { setStatus, setSubmitting }) => {
      enableLoading();
      // sendLoginOtp(formData.email)

      resetPassword({ ...formData, email: localStorage.getItem("email") })
        .then((data) => {
          disableLoading();
          if (data?.data?.data?.errorType) {
            setStatus({ status: "error", message: data?.data?.message });
          } else {
            Toaster({ message: "Password reset Success", variant: "success" });
            setStatus({
              status: "success",
              message: "Password reset successfull",
            });
            props.history.push("/login");
          }
        })
        .catch((err) => {
          disableLoading();
          setSubmitting(false);
          setStatus({
            status: "error",
            message: err.response?.data?.data?.message,
          });
        });
    },
  });

  // const Login =(params)=>{
  //   dispatch(actions.loginAction(params))
  // }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1>Forgot Password</h1>
                    <p className="text-muted">
                      Enter Email and new password to reset
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        id="otp"
                        placeholder="OTP"
                        onChange={formik.handleChange}
                        value={formik.values.otp || ""}
                      />
                    </CInputGroup>
                    {FeedBackTextGenerator(
                      formik.getFieldMeta("otp").touched,
                      formik.getFieldMeta("otp").error
                    )}

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        id="password"
                        placeholder="New Password"
                        onChange={formik.handleChange}
                        value={formik.values.password || ""}
                      />
                    </CInputGroup>
                    {FeedBackTextGenerator(
                      formik.getFieldMeta("password").touched,
                      formik.getFieldMeta("password").error
                    )}

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        id="cPassword"
                        placeholder="Confirm Password"
                        onChange={formik.handleChange}
                        value={formik.values.cPassword || ""}
                      />
                    </CInputGroup>
                    {FeedBackTextGenerator(
                      formik.getFieldMeta("cPassword").touched,
                      formik.getFieldMeta("cPassword").error
                    )}

                    {formik?.status?.status === "error" ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {formik.status.message}
                      </div>
                    ) : null}
                    <CRow>
                      <CCol xs="6">
                        {/* <CButton type="submit" color="primary" className="px-4">Login</CButton> */}
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton
                          type="button"
                          color="primary"
                          className="px-4 mr-3"
                          onClick={() => props.history.push("/")}
                        >
                          Back
                        </CButton>
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          disabled={loading}
                          onClick={formik.handleSubmit}
                        >
                          {loading ? (
                            <>
                              <CSpinner
                                component="span"
                                size="sm"
                                aria-hidden="true"
                              />
                              <span>Loading...</span>
                            </>
                          ) : (
                            <span>Change Password</span>
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
